import React from 'react';
import img2 from '../imports/images/thumbnail_170892787_m_normal_none_Modif_WEB.jpg';

const Kinesio = () => {
  return (
<div className='subContainer'>
  <div className='image_plain'>
    <img className='kinesio' src={img2} alt="Logo"/>
  </div>
    <div className='content'>
      <div className='text'>
        <h1>Qu’est-ce que la kinésiologie ?</h1>
          <p>
          La Kinésiologie est un terme générique regroupant un ensemble de techniques s’inspirant de l’acupuncture, la chiropraxie et utilise le test musculaire de précision. Il s’agit d’une pression douce exercée le plus souvent sur un muscle du bras qui indique la présence ou absence d’un stress lié à l’objectif qui a été déterminé par le consultant. Ainsi la personne participe pleinement par une auto observation consciente de ce qui se passe en elle l’amenant à devenir actrice de son propre changement, car le corps garde en « mémoire » tous les évènements impactants et le test musculaire est un moyen d’interroger cette mémoire.
          </p>
          <p>
          Aucun diagnostique est entrepris
          </p>
          <p>
          Aucun traitement médicamenteux n’est proposé
          </p>
          <p>
          Le kinésiologue peut parfaitement être complémentaire à un traitement médical
          </p>
      
      

  <h1>Domaine d’application de la kinésiologie (non exhaustif)</h1>
    <p>
        

    <ul className='kinesioList'>
      <li>-découvrir son potentiel, le développer et l’atteindre</li>
      <li>-une aide dans les phases d’apprentissage, préparer un examen</li>
      <li>-mieux gérer son stress et s’en libérer</li>
      <li>-libérer sa créativité et sa capacité de choisir</li>
      <li>-vivre des relations harmonieuses (scolaire, professionnel, familial)</li>
      <li>-apprendre à s’écouter, s’aimer et impulser de nouveaux chemins</li>
      <li>-retrouver la confiance en soi, l’estime de soi et sa joie de vivre</li>
      <li>-elle permet de lutter contre les insomnies, l’échec scolaire, la fatigue chronique, les troubles du sommeil</li>
      <li>-aide pour les séparations et les deuils</li>
      <li>-aide contre les addictions</li>
      <li>-lutte contre les angoisses et l’anxiété et facilitatrice dans les situations décourageantes</li>
    </ul>
    </p>

    <p>
    La kinésiologie s’adresse à tous ; autant aux bébés qu’aux enfants, adolescents, adultes et seniors conscients de certaines difficultés ou comportement inapte freinant leur épanouissement.
    </p>


  <h1>Déroulement d’une séance de kinésiologie</h1>
    <p>

    Toute séance de kinésiologie commence par un entretien destiné à mieux connaître le consultant en écoutant son histoire et ses besoins. A l’issue de cet échange, la personne définit le thème-objectif de sa séance.

    La personne est dès lors amenée à s’allonger sur la table de massage, habillée, sans chaussures et après avoir expliqué le fonctionnement du test musculaire, une série de pré tests permettront déjà un premier rééquilibrage énergétique garantissant la fiabilité du test musculaire pour la suite. Vient ensuite la séance proprement dite avec les équilibrations adéquates qui varient suivant ce dont le corps de la personne a le plus besoin.
    </p>

      </div>
    </div>
  </div>
  );
};
  
export default Kinesio;