import React from 'react';
import img2 from '../imports/images/sunrise-506472311_4158x3124_WEB.jpg';

const TarifAndContact = () => {
  return (
<div className='subContainer'>
  <div className='image_plain'>
    <img className='tarifs' src={img2} alt="Logo"/>
  </div>
  <div className='content'>
    <h1>Tarifs</h1>
        <p>
          Le prix d’une séance de kinésiologie ou de techniques énergétiques (Reiki et réflexologie plantaire) est de 60 EUR TTC.

          La durée est comprise entre 1h et 1h30 maximun.

          Pour les bébés, enfants, adolescents jusqu’à 17 ans, le tarif est de 50 EUR TTC et la séance dure entre 45 minutes à 1h.

          La kinésiologie, le Reiki et la réflexologie plantaire ne font pas partie de la médecine conventionnelle. Cependant certaines mutuelles sont de plus en plus nombreuses à intégrer ces spécialités dans leurs forfaits de remboursements dit «de prévention » ou « médecine douce ».
        </p>
        <p>
          Paiements : Comptant ou via l’application Payconiq.
        </p>
  </div>
</div>
    
    
    
  );
};
  
export default TarifAndContact;