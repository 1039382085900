import React from 'react';
import img2 from '../imports/images/IMG_20230319_143053_WEB.jpg';
  
const Contact = () => {
  return (

<div className='subContainer'>
  <div className='image_plain'>
    <img className='contact' src={img2} alt="Logo"/>
  </div>
  <div className='content'>
    <div>
        <h1>Me Contacter</h1>
        <p>6, Rue Johny Flick 1550 MERL Luxembourg</p>
        <p>Mardegan.patricia@gmail.com</p>
        <p>+352 691 64 62 05</p>
        <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2585.6929037861637!2d6.099642093027622!3d49.60354037435538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47954930e96488b9%3A0xf20f7fb44f3cfe06!2s6%20Rue%20Johny%20Flick%2C%201550%20M%C3%A4rel%20Luxembourg!5e0!3m2!1sfr!2slu!4v1686488677247!5m2!1sfr!2slu" title="googleFrame" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        {/* width="600" height="450" */}
    </div>
    
  </div>
</div>
  );
};
  
export default Contact;