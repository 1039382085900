import React from 'react';
  
const Footer = () => {
  const getCurrentYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
  };
  const year_test = getCurrentYear()
  return (
      <div className=' footer'>
              Copyright © {year_test}
      </div>
  );
};
  
export default Footer;