import { Link, useMatch, useResolvedPath } from "react-router-dom"
import logo from '../imports/images/Logo_Patricia.jpg';

export default function Navbar() {
  return (
<div className="navBar">
  <div>
    {/* <img src={logo} alt="Logo"/> */}
  </div>
  <div>
    <nav className="nav">
      <ul>
        <CustomLink to="/">Présentation</CustomLink>
        <CustomLink to="/Reiki">Reiki</CustomLink>
        <CustomLink to="/Kinesiologie">Kinésiologie</CustomLink>
        <CustomLink to="/Reflexologie">Réflexologie</CustomLink>
        <CustomLink to="/Tarifs">Tarifs</CustomLink>
      </ul>
    </nav>
  </div>
  <div className="buttonDiv">
    <CustomLink to="/Contact" className="button">Me Contacter
    {/* <button className="button">Me Contacter</button>  */}
    </CustomLink>
  </div>

</div>

  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}