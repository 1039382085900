import React from 'react';
import img1 from '../imports/images/thumbnail_172094986_m_normal_none_MODIF_2-WEB.jpg';


const Reflexologie = () => {
  return (
    
        <div className='subContainer'>

          <div className='image_plain'>
          <img className='reflexo' src={img1} alt="Logo"/>
          </div>
          <div className='content'>
          <div className='text'>
            <h1>Qu’est ce que la réflexologie plantaire ?</h1>
                    <p>
                    La réflexologie plantaire est une méthode douce et naturelle. Elle se pratique en des mouvements de «reptation», de «pression et rotation», de lissage et d’effleurements pratiqués sur les pieds.

                    Tous les systèmes physiologiques tels que les appareils respiratoire, digestif ,hormonal, circulatoire, nerveux, lymphatique, sensoriel, articulaire et urinaire ont leur projection précise sur les pieds. Chaque partie du corps humain est projeté en miniature à un endroit précis sur le pied, appelé zone réflexe.

                    Le corps va toujours dans le sens de l’équilibre et la maladie est l’équilibre le plus parfait que le corps a pu trouver pour une situation donnée…
                    </p>
            <h1>Domaine d’application de la réflexologie plantaire</h1>
                    <p>
                    La réflexologie plantaire va stimuler et équilibrer notre organisme. Elle va traiter le symptôme en profondeur en remontant à son origine et ainsi apporter un soulagement réel et un bien-être.

                    Elle réduit les tensions nerveuses, diminue les douleurs articulaires et musculaires, améliore la qualité du sommeil et stimule aussi la circulation sanguine et lymphatique.

                    La réflexologie plantaire va rééquilibrer et stimuler le système digestif (constipation et+), réduire voire faire disparaître les migraines.

                    Effet relaxant garanti, elle ralentit l’activité mentale qui a pour conséquence de diminuer le stress et les tensions physiques liées aux émotions.

                    Mal de dos, hypertension, arthrites, bronchites chroniques, règles douloureuses et bien d’autres troubles trouvent une amélioration notoire.
                    </p>
            <h1>Déroulement d’une séance de réflexologie plantaire</h1>
                    <p>
                    On débute la séance en faisant une évaluation générale de l’état de la personne.

                    Une séance de réflexologie plantaire s’applique aux deux pieds et à l’ensemble des zones réflexes.

                    La personne s’allonge habillée sur la table de massage, pieds nus.

                    La séance dure en général une heure. Un traitement se déroule sur plusieurs séances dont le nombre varie selon l’individu et sa capacité de guérison naturelle.

                    Toute personne est traité individuellement et certaines situations sont travaillées avec précautions.
                    </p>
          </div>
          </div>
        </div>
    
  );
};
  
export default Reflexologie;