import React from 'react';
import img2 from '../imports/images/thumbnail_rocks-7354363_WEB.jpg';

  
const Reiki = () => {
  return (

<div className='subContainer'>
  <div className='image_plain'>
    <img className='reiki' src={img2} alt="Logo"/>
  </div>
  <div className='content'>
    <div className='text'>

    <h1>Qu’est- ce que le REIKI</h1>
      
      <p>
      Le REIKI est originaire du Japon et signifie « Force de Vie Cosmique » ayant le pouvoir de regénérer tout l’organisme lorsqu’on a recourt à elle. C’est une onde librement disponible pour tout le monde.

      Il s’agit d’une technique mise au point par Mikao Usui (1865-1926) au Japon.

      Un praticien Reiki est une personne ayant reçu la capacité d’utiliser l’énergie Reiki à travers un protocole d’initiation par un Maître Reiki.

      </p>

    <h1>Domaine d’application du Reiki</h1>
      <p>
      Une séance de Reiki met à la disposition du corps concerné toute l’énergie vitale dont il a besoin. Ainsi, cette énergie déclenche souvent une réaction de bien-être en chaîne qui stimule un processus d’autoguérison pour lequel le praticien n’est que l’accompagnateur.

      Le Reiki atténue les douleurs jusqu’à les faire disparaître. Il dissout les blocages physiques, énergétiques et psychiques.

      Il favorise le sommeil et le mieux-être et vous permet d’atteindre la détente profonde.

      Votre énergie vitale en vient accrue et il renforce tous les systèmes du corps.

      Un soin Reiki peut parfaitement être complémentaire à un traitement médical.
      </p>


    <h1>Déroulement d’une séance de Reiki</h1>
      <p>
                
      J’invite la personne à s’étendre sur la table de massage, déchaussée, habillée, après avoir échanger sur la problématique de son choix.

      Une séance de Reiki consiste à imposer les mains sur toutes les parties du corps qui en ont le besoin, voire même sur la totalité si cela s’avère nécessaire pour la personne.

      Si la personne ne souhaite pas être touchée, le praticien effectue les mêmes gestes sans toucher le corps.

      Le séance durera entre trois quarts d’heure et une heure.

      Les mains posées, il n’y a plus qu’à laisser s’écouler l’énergie.

      Le Reiki ayant sa propre conscience, il travaillera sur la cause profonde d’une problématique.

      Lors de la séance, le Reiki va remplir les vides et dissoudre les blocages énergétiques. Le Reiki va choisir l’endroit où il est urgent d’intervenir. Cet endroit peut se situer au niveau

      physique, mental, émotionnel ou spirituel. Il va nettoyer et éliminer le trop plein des toxines. Ce nettoyage s’opérant via les liquides du corps , il est recommandé de boire plus d’eau que d’habitude.

      Pendant ce travail, il arrive de ressentir de l’inconfort (rare), voire même quelques douleurs. N’ayez crainte et accueillez l’un et l’autre avec confiance car ils sont l’expression du réveil des mécanismes d’auto-guérison d’une part et les signes prometteurs d’une guérison profonde d’autre part.
      </p>
    </div>
    
  </div>
</div>
  );
};
  
export default Reiki;