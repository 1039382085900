import React from 'react';
import './App.css';
import './scss/App.scss';
import Navbar from './components/navbar';
import { Routes, Route } from 'react-router-dom';
import Reiki from './pages/reiki.js';
import Presentation from './pages/presentation.js';
import Kinesio from './pages/kinesiologie.js';
import Reflexologie from './pages/reflexologie';
import Contact from './pages/contact.js';
import TarifAndContact from './pages/contactAndTarifs';
import Footer from './components/footer';


function App() {
return (
  <>
  
	
    <Navbar />
      <Routes>
        <Route path='/' exact element={<Presentation />} />
        <Route path='/Reiki' element={<Reiki />} />
        <Route path='/Kinesiologie' element={<Kinesio />} />
        <Route path='/Reflexologie' element={<Reflexologie />} />
        <Route path='/Tarifs' element={<TarifAndContact />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
        <Footer/>
	
  </>
);
}

export default App;
