import React from 'react';
import img1 from '../imports/images/IMG_20200726_191733.jpg';


const Presentation = () => {
  return (
    
        <div className='subContainer'>

          <div className='image_plain'>
          <img src={img1} alt="Logo"/>
          </div>
          <div className='content'>

            <h1>Qui suis-je ?</h1>
                    <p>
                    Je m’appelle Patricia Mardegan, 52 ans, maman de 2 enfants et employée du secteur bancaire. Je suis passionnée par le bien-être et les médecines douces. Je suis kinésiologue, réflexologue plantaire et praticienne Reiki.

                    Je me suis ouverte aux soins holistiques il y a plus de 10 ans lorsque le père de mes enfants a déclaré une maladie auto-immune. Depuis, je me dédie à toutes ces techniques de bien-être ayant pour objectif d’amener la personne au mieux de ses possibilités physiques, mentales, énergétiques afin de retrouver un état de santé optimal libéré de toute tension.
                    </p>
          </div>

        </div>
    
  );
};
  
export default Presentation;